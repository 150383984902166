import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      topScreen: 1,
      bottomScreen: 1,
      paginationCountActive: false,
      timeout: null,
      screen: null,
      currentPage: 1,
      doNotDeletePaginationRoutes: [
        "r_manage-users",
        "r_resources",
        "r_qr-codes"
      ]
    };
  },
  computed: {
    ...mapState("pagination", ["paginationData"]),
    routeName() {
      return this.$route;
    }
  },
  watch: {
    routeName() {
      const parentRoutes = this.$route.matched.slice(0, -1);
      parentRoutes.forEach(route => {
        if (!this.doNotDeletePaginationRoutes.includes(route.name)) {
          this.topScreen = 1;
          this.setPaginationData(null);
        }
      });
      // roles
      if (
        this.$route.name.includes("r_roles") &&
        this.$route.matched.length == 1
      ) {
        this.topScreen = 1;
        this.setPaginationData(null);
      }
      // containers
      if (
        this.$route.name.includes("r_containers") &&
        this.$route.matched.length == 1
      ) {
        this.topScreen = 1;
        this.setPaginationData(null);
      }
    },
    topScreen() {
      if (
        this.paginationData &&
        this.paginationData.current_page > 2 &&
        this.paginationData.to - this.topScreen < 200 &&
        this.paginationData.current_page < this.paginationData.last_page &&
        this.currentPage != this.paginationData.current_page
      ) {
        this.currentPage = this.paginationData.current_page;
        this.executeCommand();
      }
      if (this.topScreen > 1) {
        this.paginationCountActive = true;
        if (this.timeout) {
          window.clearTimeout(this.timeout);
        }
        this.timeout = window.setTimeout(() => {
          this.paginationCountActive = false;
        }, 3000);
      }
    }
  },
  methods: {
    ...mapActions("pagination", ["setPaginationData"]),
    handleScroll() {
      let list = this.screen.querySelectorAll("ul");
      list = list[list.length - 1];
      if (list) {
        let screenHeaderHeight = this.screen.getBoundingClientRect().top;
        let screenHeight = this.screen.offsetHeight;

        let top = 0;
        var elements = list.querySelectorAll("li");

        if (list.getBoundingClientRect().top > screenHeaderHeight) {
          this.topScreen = 1;
        } else {
          top = Array.from(elements).findIndex(el => {
            return (
              el.getBoundingClientRect().top < screenHeaderHeight &&
              el.getBoundingClientRect().top + el.offsetHeight >
                screenHeaderHeight
            );
          });

          if (top !== -1) {
            this.topScreen = top + 1;
          }
        }

        if (
          list.getBoundingClientRect().top + list.offsetHeight <
          screenHeaderHeight + screenHeight
        ) {
          this.bottomScreen = Array.from(elements).length;
        } else {
          let bottom = Array.from(elements).findIndex(el => {
            return (
              el.getBoundingClientRect().top <
                screenHeaderHeight + screenHeight &&
              el.getBoundingClientRect().top + el.offsetHeight >
                screenHeaderHeight + screenHeight
            );
          });

          if (bottom !== -1) {
            this.bottomScreen = bottom + 1;
          }
        }
      }
    },
    executeCommand() {
      if (this.$route.path.includes("manage-users")) {
        this.getCompanyUserList();
      } else if (this.$route.path.includes("resources")) {
        this.getResources();
      } else if (this.$route.path.includes("qr-codes")) {
        this.getQrCodes();
      } else if (this.$route.path.includes("add-users-to-container")) {
        this.getUsers();
      } else if (this.$route.path.includes("add-roles-to-container")) {
        this.fetchRoles();
      } else if (this.$route.path.includes("roles-")) {
        this.fetchRoles();
      } else if (this.$route.path.includes("containers-")) {
        this.fetchContainers();
      } else if (this.$route.path.includes("add-levels-to-container")) {
        this.getLevelsList();
      } else if (this.$route.path.includes("realtime")) {
        this.getMaps();
      } else if (this.$route.path.includes("qr-code-templates")) {
        this.getTemplates();
      } else {
        this.getResources();
      }
    }
  },
  created() {
    this.setPaginationData(null);
  },
  mounted() {
    this.setPaginationData(null);
    const screens = document.querySelectorAll(".screen-content");
    screens.forEach(div => {
      if (div.querySelector(".scroll")) {
        this.screen = div;
      }
    });
    this.screen.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    this.screen.removeEventListener("scroll", this.handleScroll);
  }
};
